<template>
    <div>
        <div v-if="authUserPermission['material-status-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--7">
                <div class="row mt--5">
                    <div class="col-xl-4 col-md-6">
                        <stats-card :title="tt('all_materials')" icon="fa fa-cog" :sub-title="total.spare_parts" type="primary">
                        </stats-card>
                    </div>
                    <div class="col-xl-4 col-md-6">
                        <stats-card :title="tt('fast_moving')" icon="fa fa-paper-plane" :sub-title="total.fast_moving" type="info">
                        </stats-card>
                    </div>
                    <div class="col-xl-4 col-md-6">
                        <stats-card :title="tt('others')" icon="ni ni-chart-bar-32" :sub-title="total.others" type="warning">
                        </stats-card>
                    </div>
                </div>

                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-4">
                                <base-input :name="tt('company_code')">
                                    <el-select v-model="company_code" :placeholder="tt('company_code')" @change="onChange($event)">
                                        <el-option class="select-danger" value="" :label="tt('all_company_code')"></el-option>
                                        <el-option class="select-danger" :value="row.company_code" :label="row.company_code+' - '+row.company_description" :key="row.company_code" v-for="row in companyList"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                            <div class="col-8 text-right">
                                <a v-if="authUserPermission['material-status-export']" :href="apiUrl + 'MaterialStatusDashboard/export?token=' + token" class="btn btn-sm btn-outline-dark">
                                    {{ tt('export_as_excel') }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center" colspan="9">{{ date }}</th>
                            </tr>
                        </thead>
                    </table>
                    <div class="card-body">
                        <div class="row" v-if="!onLoad.table">
                            <div class="col-md-12" v-for="(row, index) in table.data">
                                <h5>{{ tt('company_code')}} : {{row.company_code}}</h5>
                                <div class="row">
                                    <div class="col-md-3">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="text-center" style="background-color: #ededed" colspan="2">{{ tt('material_type') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data, index) in row.data.material_type">
                                                    <td>{{data.mtype_code}}</td>
                                                    <td>{{data.total_material_type}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-3">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="text-center" style="background-color: #ededed" colspan="2">{{ tt('mrp_controller') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data, index) in row.data.mrp_controller">
                                                    <td>{{data.mrp_controller_code}}</td>
                                                    <td>{{data.total_mrpcon}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-3">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="text-center" style="background-color: #ededed" colspan="2">{{ tt('mrp_type') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data, index) in row.data.mrp_type">
                                                    <td>{{data.mrp_type_code}}</td>
                                                    <td>{{data.total_mrptype}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-3">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="text-center" style="background-color: #ededed" colspan="2">{{ tt('abc_indicator') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data, index) in row.data.abc_indicator">
                                                    <td>{{data.abc_indicator_code}}</td>
                                                    <td>{{data.total_abcindicator}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <hr>
                            </div>
                        </div>
                        <skeleton-loading v-else/>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
	import {mapState} from "vuex";
	import Api from '@/helpers/api';
	import materialStatusDasboard from '@/services/dashboard/materialStatusDashboard.service';
	import companyCode from '@/services/master/companyCode.service';
	import config from '@/configs/config';

	export default {
		data() {
			return {
				date: '',
                total: {
					spare_parts: '0',
                    fast_moving: '0',
                    others: '0'
                },
				table: {
					data: []
				},
				onLoad: {
					table: true
				},
                companyList: [],
                company_code: '',
				apiUrl: config.apiUrl,
				token: localStorage.getItem('token')
			}
		},
		computed: {
			...mapState({
				authUserPermission :state => state.auth.userPermission
			}),
		},
		mounted() {
			const today = new Date();
			this.date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
			this.companies();
            this.get();
            this.fastMoving();
			this.spareParts();
		},
		methods: {
			onChange(val) {
				this.onLoad.table = true;
				this.get(val);
				this.spareParts(val);
				this.fastMoving(val);
			},
			companies() {
				let context = this;
				Api(context, companyCode.get()).onSuccess(function(response) {
					context.companyList = response.data.data.data.data;
				}).onError(function(error) {
					context.companyList = [];
				}).call()
            },
			get(param = '') {
				let context = this;
				Api(context, materialStatusDasboard.get({search: param})).onSuccess(function(response) {
					context.table.data = response.data.data;
					context.onLoad.table = false;
				}).onError(function(error) {
					context.table.data = [];
					context.onLoad.table = false;
				}).call()
			},
			spareParts(param = '') {
				let context = this;
				Api(context, materialStatusDasboard.spare_parts({search: param})).onSuccess(function(response) {
					context.total.spare_parts = response.data.toString();
                    console.log(context.total.spare_parts)
				}).onError(function(error) {
					context.total.spare_parts = 0;
				}).call()
			},
			fastMoving(param = '') {
				let context = this;
				Api(context, materialStatusDasboard.fast_moving({search: param})).onSuccess(function(response) {
					context.total.fast_moving = response.data.toString();;
				}).onError(function(error) {
					context.total.fast_moving = 0;
				}).call()
			},
			// others() {
			// 	let context = this;
			// 	Api(context, materialStatusDasboard.totalItemMcr()).onSuccess(function(response) {
			// 		context.total.others = response.data.toString();
			// 	}).onError(function(error) {
			// 		context.total.others = 0;
			// 	}).call()
			// },
		}
	};
</script>
