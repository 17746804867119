import Service from '@/helpers/service'

export default {
	get(params) {
		return Service().get('MaterialStatusDashboard', {params});
	},
	spare_parts(params) {
		return Service().get('MaterialStatusDashboard/all-spare-parts', {params});
	},
	fast_moving(params) {
		return Service().get('MaterialStatusDashboard/all-fast-moving', {params});
	},
}
